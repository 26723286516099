<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="promotion.code" placeholder="Auto Generate" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Bulletin Ex Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="promotion.group_code" :readonly="MonitoringType=='approval'" required/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Promotion Type</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedPromotionType"
                            :options="optionPromotionType"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="ID"
                            label="Code"
                            :disabled="MonitoringType=='approval'"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Name</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="promotion.name" :readonly="MonitoringType=='approval'" required/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Description</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" :label="charactersLeft" name="Description" v-model="promotion.description" :readonly="MonitoringType=='approval'" required/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Currency</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedCurrency"
                            :options="optionCurrency"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="Code"
                            label="Code"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Ownership</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.ownership">
                        <label> Internal</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.ownership">
                        <label> Supplier</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6" v-if="promotion.ownership==2">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Supplier</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedSupplier"
                            :options="optionSupplier"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="ID"
                            label="Name"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code + " " + dt.option.Name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid from</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.valid_from" placeholder="Select valid from" required :disabled="MonitoringType=='approval'"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid to</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.valid_to" placeholder="Select valid to" required :disabled="MonitoringType=='approval'"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Unit</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=1 v-model="promotion.unit" :disabled="MonitoringType=='approval'">
                        <label> Line</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=2 v-model="promotion.unit" :disabled="MonitoringType=='approval'">
                        <label> Total</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Invoice Type</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.invoice_type" :disabled="MonitoringType=='approval'">
                        <label> On Invoice</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="0" v-model="promotion.invoice_type" :disabled="MonitoringType=='approval'">
                        <label> Off Invoice</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Flush Out</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.flush_out" :disabled="MonitoringType=='approval'">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="0" v-model="promotion.flush_out" :disabled="MonitoringType=='approval'">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6" v-if="promotion.flush_out == 1">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Mix Exp Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.flush_out_min_exp_date" placeholder="Select Min Exp Date" :disabled="MonitoringType=='approval'"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6" v-if="promotion.flush_out == 1">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Max Exp Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.flush_out_max_exp_date" placeholder="Select Max Exp Date" :disabled="MonitoringType=='approval'"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Budget</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedBudget"
                            :options="optionBudget"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="ID"
                            label="Code"
                            @input="handleSelectBudget"
                            :disabled="MonitoringType=='approval'"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Allow Partial</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.allow_partial" :disabled="MonitoringType=='approval'">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="0" v-model="promotion.allow_partial" :disabled="MonitoringType=='approval'">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Allow Return</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.allow_return" :disabled="MonitoringType=='approval'">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="0" v-model="promotion.allow_return" :disabled="MonitoringType=='approval'">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Mix SKU</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.sku_type" :disabled="this.promotion.line.length > 0||MonitoringType=='approval'">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="0" v-model="promotion.sku_type" :disabled="this.promotion.line.length > 0||MonitoringType=='approval'">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Type</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.type" :disabled="MonitoringType=='approval'">
                        <label> Discount</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.type" :disabled="MonitoringType=='approval'">
                        <label> Free Item</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Base</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=1 v-model="promotion.base" :disabled="MonitoringType=='approval'">
                        <label> Quantity</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=2 v-model="promotion.base" :disabled="MonitoringType=='approval'">
                        <label> Value</label>
                    </div>
                    <!-- <div class="vx-col sm:w-1/3">
                        <input type="radio" value=3 v-model="promotion.base" :disabled="MonitoringType=='approval'">
                        <label> Weight</label>
                    </div> -->
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Calculation</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=1 v-model="promotion.calculation" disabled>
                        <label> Final</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=2 v-model="promotion.calculation" disabled>
                        <label> Step Ladder</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>All Handling Unit</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=1 v-model="promotion.all_hu">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value=0 v-model="promotion.all_hu">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                <span>Attachment</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                <input
                    id="fileInput"
                    name="file"
                    class="w-full inputx"
                    type="file"
                    ref="file"
                    @input="previewFiles"
                    multiple="multiple"
                    accept=".jpg, .jpeg, .png .pdf"
                    :disabled="MonitoringType=='approval'"
                />
                </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 2%" v-if="fileAttachment.length>0">List Attachment</vs-divider>
            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%" v-if="fileAttachment.length>0">
                <table class="vs-table vs-table--tbody-table">
                    <tr class="tr-values vs-table--tr tr-table-state-null selected" :key="i" v-for="(tr, i) in fileAttachment">
                        <td class="td vs-table--td">{{ tr.file_name }} {{ tr.size?"("+tr.size+"kb)" :""}}</td>
                        <td class="td vs-table--td p-0" width="5%">
                            <vx-tooltip text="Download" v-if="tr.path != ''" >
                                <vs-button type="line" icon-pack="feather" icon="icon-download"
                                    @click.stop="downloadFileAwsS3(tr.path)" />
                            </vx-tooltip>
                        </td>
                        <td width="5%" class="td vs-table--td p-0">
                            <template>
                                <vx-tooltip text="Delete" class="text-center" >
                                    <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                        @click.stop="handleDeleteAttachment(i, tr.id)" />
                                </vx-tooltip>
                            </template>
                        </td>
                        </tr>
                </table>
            </div>
        </div>
        <div class="vx-col w-full mb-base">
            <TemplateApplicableTo ref="template_applicable_to" :optionType="optionType" :promotion="promotion" :templateTerritory = "templateTerritory" :templateCustomerCategory = "templateCustomerCategory" :templateCustomerGroup1 = "templateCustomerGroup1" :templateCustomerGroup2 = "templateCustomerGroup2" :templateCustomerGroup3 = "templateCustomerGroup3" :templatePricingGroup = "templatePricingGroup" :templateCustomerSoldTo = "templateCustomerSoldTo" :templateCustomerShipTo = "templateCustomerShipTo" :templatePaymentTerm = "templatePaymentTerm" :templateDeliveryType = "templateDeliveryType"  v-on:browseParameter="browseParameter" v-on:addLine="addLine" v-on:removeTemplate="removeTemplate" v-on:removeTemplateDetail="removeTemplateDetail" :MonitoringType="MonitoringType"></TemplateApplicableTo>
        </div>
        <hr>
        <div class="vx-col w-full mb-base"><center><h3>Promotion Line</h3></center></div>
        <br>
        <div class="vx-col w-full mb-base">
            <vs-table :data="promotion.line" stripe border>
                <template slot="thead">
                    <!-- <vs-th>Validity</vs-th> -->
                    <vs-th>Div</vs-th>
                    <vs-th>Category</vs-th>
                    <vs-th>Material</vs-th>
                    <vs-th>Combo</vs-th>
                    <vs-th>SKU</vs-th>
                    <vs-th v-if="parseInt(this.promotion.sku_type) == 1">Mandatory</vs-th>
                    <vs-th>Range</vs-th>
                    <vs-th>Disc</vs-th>
                    <vs-th v-if="this.promotion.type == 2">Free Good</vs-th>
                    <vs-th>Multiply</vs-th>
                    <vs-th v-if="MonitoringType!='approval'">Action</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="idx" v-for="(dt, idx) in data">
                        <!-- <vs-td>
                            From : {{ dateFormat(dt.valid_from) }} <br>
                            To : {{ dateFormat(dt.valid_to) }}
                        </vs-td> -->
                        <vs-td>{{ dt.division && dt.division.id ? dt.division.code : 'all' }}</vs-td>
                        <vs-td>
                            Category 1 : {{ dt.category_1 && dt.category_1.id ? dt.category_1.code : 'all' }} <br>
                            Category 2 : {{ dt.category_2 && dt.category_2.id ? dt.category_2.code : 'all' }}
                        </vs-td>
                        <vs-td>
                            Material 1 : {{ dt.material_1 && dt.material_1.id ? dt.material_1.code : 'all' }} <br>
                            Material 2 : {{ dt.material_2 && dt.material_2.id ? dt.material_2.code : 'all' }} <br>
                            Material 3 : {{ dt.material_3 && dt.material_3.id ? dt.material_3.code : 'all' }}
                        </vs-td>
                        <vs-td>{{ dt.combo && dt.combo.id ? dt.combo.code : 'all' }}</vs-td>
                        <vs-td>
                            <span>{{ dt.item && dt.item.id ? dt.item.full_text : 'all' }}</span>
                        </vs-td>
                        <vs-td v-if="parseInt(promotion.sku_type) == 1">
                            <vs-switch v-model="dt.mandatory"/>
                        </vs-td>
                        <vs-td>
                            {{ dt.from }} - {{ dt.to }} <span v-if="dt.item_unit">{{ dt.item_unit }}</span>
                        </vs-td>
                        <vs-td v-if="promotion.type == 1">
                            type : {{ dt.type.name }} <br>
                            main : {{ dt.disc }} <br>
                            add : {{ dt.disc_add ? dt.disc_add : "-" }} <br>
                            cap : {{ dt.disc_cap ? dt.disc_cap : "-" }}
                        </vs-td>
                        <vs-td v-if="promotion.type == 2">
                            type : {{ dt.type.name }} <br>
                            cap : {{ dt.disc_cap ? dt.disc_cap : "-" }}
                        </vs-td>
                        <vs-td v-if="promotion.type == 2">
                            <vs-button size="small" class="vs-button-dark mr-3 mb-2" v-on:click="browseParameter(17, idx)" v-if="MonitoringType!='approval'">Add</vs-button>
                            <table style="text-align: center; vertical-align: middle;">
                                <tr>
                                    <th style="border: 1px solid; border-collapse: collapse;">SKU</th>
                                    <th style="border: 1px solid; border-collapse: collapse;">Qty</th>
                                    <th style="border: 1px solid; border-collapse: collapse;">Unit</th>
                                    <th style="border: 1px solid; border-collapse: collapse;" v-if="MonitoringType!='approval'">Action</th>
                                </tr>
                                <tr :key="idx_fi" v-for="(fi, idx_fi) in dt.free_item">
                                    <td style="border: 1px solid; border-collapse: collapse;">{{ fi.item_code }}</td>
                                    <td style="border: 1px solid; border-collapse: collapse;" class="sm:w-1/6">{{ fi.qty }}</td>
                                    <td style="border: 1px solid; border-collapse: collapse;">{{ fi.item_unit }}</td>
                                    <vs-td style="border: 1px solid; border-collapse: collapse;" v-if="MonitoringType!='approval'">
                                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-4 text-danger" @click="clickDeleteFreeItem(idx, idx_fi)"/>
                                    </vs-td>
                                </tr>
                            </table>
                        </vs-td>
                        <vs-td>
                            <span v-if="dt.disc_per != null && dt.disc_per != '' && dt.disc_per != 0 && !isNaN(dt.disc_per)">per {{ dt.disc_per }} {{ dt.disc_per_unit ? dt.disc_per_unit.name : "" }}</span>
                            <span v-else>No</span>
                        </vs-td>
                        <vs-td v-if="MonitoringType!='approval'">
                            <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDeleteLine(idx)"/>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <br>
            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full">
                    <!--<vs-button class="mr-3 mb-2" v-on:click="handleAttachmant()">Submit</vs-button> -->
                    <vs-button v-if="MonitoringType!='approval' && allowSubmit" class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <!-- <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button> -->
                    <vs-button v-if="update||MonitoringType=='approval'" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>

        <vs-popup :title="'Applicable to'" :active.sync="optionParameterShow">
            <TableOptionParameter ref="table_option_parameter" v-on:selectTemplate="selectTemplate"></TableOptionParameter>
        </vs-popup>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import TemplateApplicableTo from "../component/template.vue";
import TableOptionParameter from "../component/table_option_parameter.vue";
import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedPromotion", "optionTerritory","MonitoringType"],
    components: {
        Datepicker,
        flatPickr,
        TemplateApplicableTo,
        TableOptionParameter
    },
    mounted(){
        // console.log("userLogin : ", this.$userLogin);
        // console.log("this.selectedPromotion : ", this.selectedPromotion);
        if(this.selectedPromotion && this.selectedPromotion.code) {
            this.resetPromotion = Object.assign({}, this.selectedPromotion);
            this.promotion = this.selectedPromotion;
            this.promotion.line = this.selectedPromotion.item_requisition
            this.promotion.Attachment.forEach((el)=>{
                this.fileAttachment.push({
                    path:el.path,
                    file_name: el.file_name,
                    size:0,
                    id :el.id,
                    promotion_id:el.v2_promotion_id,
                    file:null,
                })
            })
            this.promotion.valid_from = new Date(this.promotion.valid_from)
            this.promotion.valid_to = new Date(this.promotion.valid_to)
            this.promotion.flush_out_max_exp_date = new Date(this.promotion.flush_out_max_exp_date)
            this.promotion.flush_out_min_exp_date = new Date(this.promotion.flush_out_min_exp_date)

            this.allowSubmit = true;
            this.update = true;
            if(this.promotion.budget_id != 0 && this.promotion.budget) {
              this.selectedBudget = {
                  ID: this.promotion.budget_id,
                  Code: this.promotion.budget.code,
              }
            }

            if(this.promotion.allow_partial == true && this.promotion.allow_partial != null) {
                this.promotion.allow_partial = 1
            } else {
                this.promotion.allow_partial = 0
            }

            if(this.promotion.allow_return == true && this.promotion.allow_return != null) {
                this.promotion.allow_return = 1
            } else {
                this.promotion.allow_return = 0
            }

            if(this.promotion.invoice_type == true && this.promotion.invoice_type != null) {
                this.promotion.invoice_type = 1
            } else {
                this.promotion.invoice_type = 0
            }

            if(this.promotion.flush_out == true && this.promotion.flush_out != null) {
                this.promotion.flush_out = 1
            } else {
                this.promotion.flush_out = 0
            }

            // 2: territory
            // 3: customer_category (dist channel)
            // 5: pricing group (cust group 4)
            // 9: customer group 1
            // 10: customer group 2
            // 11: customer group 3
            // 12: customer sold to (customers)
            // 13: customer ship to (customer address)
            // 14: division
            // 15: combo
            // 16: item (sku + hu)

            if(this.promotion.territory.length > 0) {
                let tempIds = []
                for (let i=0; i < this.promotion.territory.length; i++) {
                    tempIds.push(this.promotion.territory[i].territory_id)
                }
                this.setDefaultTemplate(2, this.templateTerritory, tempIds)
            }
            if(this.promotion.customer_category.length > 0) {
                let tempIds = []
                for (let i=0; i < this.promotion.customer_category.length; i++) {
                    tempIds.push(this.promotion.customer_category[i].customer_category_id)
                }
                this.setDefaultTemplate(3, this.templateCustomerCategory, tempIds)
            }
            if(this.promotion.customer_group.length > 0) {
                let tempId1s = []
                let tempId2s = []
                let tempId3s = []
                for (let i=0; i < this.promotion.customer_group.length; i++) {
                    if(this.promotion.customer_group[i].level == 1) {
                        tempId1s.push(this.promotion.customer_group[i].customer_group_id)
                    }
                    if(this.promotion.customer_group[i].level == 2) {
                        tempId2s.push(this.promotion.customer_group[i].customer_group_id)
                    }
                    if(this.promotion.customer_group[i].level == 3) {
                        tempId3s.push(this.promotion.customer_group[i].customer_group_id)
                    }
                }
                this.setDefaultTemplate(9, this.templateCustomerGroup1, tempId1s)
                this.setDefaultTemplate(10, this.templateCustomerGroup2, tempId2s)
                this.setDefaultTemplate(11, this.templateCustomerGroup3, tempId3s)
            }
            if(this.promotion.pricing_group.length > 0) {
                let tempIds = []
                for (let i=0; i < this.promotion.pricing_group.length; i++) {
                    tempIds.push(this.promotion.pricing_group[i].pricing_group_id)
                }
                this.setDefaultTemplate(5, this.templatePricingGroup, tempIds)
            }

            if(this.promotion.customer_address.length > 0) {
                let tempId1s = []
                let tempId2s = []
                for (let i=0; i < this.promotion.customer_address.length; i++) {
                    if(this.promotion.customer_address[i].type == 1) {
                        tempId1s.push(this.promotion.customer_address[i].customer_address_id)
                    } else if(this.promotion.customer_address[i].type == 2) {
                        tempId2s.push(this.promotion.customer_address[i].customer_address_id)
                    }
                }
                if(tempId1s.length > 0) {
                    this.setDefaultTemplate(12, this.templateCustomerSoldTo, tempId1s)
                }
                if(tempId2s.length > 0) {
                    this.setDefaultTemplate(13, this.templateCustomerShipTo, tempId2s)
                }
            }
            this.setLine()
            // this.groupByLineNumber()
        } else {
           this.setDefaultDate();
           this.resetPromotion = Object.assign({}, this.promotion);
        }

        this.getOptionBudget();
        this.getOptionPromotionType();
        this.getOptionCurrency();
        this.getOptionSupplier();
        this.setDefaultPromotionLineType();
    },
    watch: {
        promotionType: function() {
            this.setDefaultPromotionLineType()
        },
        search: function() {
            if(this.optionItemAvailableShow || this.optionItemFreeItemShow) {
                this.getOptionItemAvailable();
            }
        }
    },
    computed: {
        DueDate: {
            get() {
                var d = new Date()
                if(this.selectedPromotion && this.selectedPromotion.DueDate){
                    d = new Date(this.selectedPromotion.DueDate)
                    d.setHours(d.getHours() - 7)
                } else {
                    d = new Date(this.promotion.DueDate)
                }
                return d
            },
            set(val) {
                this.DueDatePost = val
                return val
            },
        },
        charactersLeft() {
            var char = this.promotion.description.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow || this.optionItemFreeItemShow) {
                    this.getOptionItemAvailable(page);
                }
                return page;
            }
        },
        promotionType() {
            return this.promotion.type
        },
    },
    data: () => ({
        configFlatPickrDueDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y H:i',
            enableTime: true,
            minTime: "00:00",
            plugins: [new ConfirmDatePlugin()]
        },
        fileAttachment:[],

        pa_ids:[],
        pa_del_ids:[],

        configFlatPickrDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y',
            enableTime: false,
        },

        hour: "",
        minute: "",

        allowSubmit: true,
        update: false,

        selectedBudget:null,
        selectedCurrency: null,
        selectedSupplier: null,
        selectedPromotionType:null,

        resetPromotion: {},
        promotion: {
            id: 0,
            budget: {},
            promotion_type: {},
            code: null,
            name: null,
            description: "",
            group_code: null,
            group_ex_code: null,
            allow_partial: 1,
            allow_return: 0,
            invoice_type: 1,
            type: 1,
            flush_out: 0,
            sku_type: 0,
            base: 1,
            unit: 1,
            calculation: 1,
            all_hu: 1,
            valid_from: null,
            valid_to: null,
            flush_out_min_exp_date: null,
            flush_out_max_exp_date: null,
            budget_id: 0,
            promotion_type_id: 0,
            line: []
        },

        optionType: [
            {id: 1,name: "Percentage"},
            {id: 2,name: "Amount"},
            // {id: 3,name: "Fix Amount"},
            // {id: 4,name: "Fix Item"},
            {id: 5,name: "Lowest Price"},
            {id: 6,name: "SKU"},
            // {id: 7,name: "Merchandise"},
            // {id: 8,name: "Percentage"},
            {id: 9,name: "Choice"},
        ],
        optionBudget: [],
        optionPromotionType: [{}],
        optionCurrency: [{}],
        optionSupplier: [{}],
        optionItemAvailable: [{}],
        optionItemAvailableShow: false,
        optionItemFreeItemShow: false,
        optionParameterShow: false,

        templateTerritory : [],
        templateCustomerCategory : [],
        templateCustomerGroup : [],
        templateCustomerGroup1 : [],
        templateCustomerGroup2 : [],
        templateCustomerGroup3 : [],
        templatePricingGroup : [],
        templateCustomerSoldTo : [],
        templateCustomerShipTo : [],
        templatePaymentTerm : [],
        templateDeliveryType : [],

        limits: [10],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY')
            }
        },
        browseParameter(type, idx=0, params = {}) {
            let allowAction = true
            if(this.MonitoringType=='approval') {
              allowAction = false
            }
            const el = this.$refs.table_option_parameter
            if(el) { // if statement here is important!
                if(type==17) { // free item
                    el.setParameterType(type, {"category": "free_item", "idx": idx, "allow_action": allowAction});
                } else {
                    el.setParameterType(type, {"category": "item_requisition", "idx": idx, "allow_action": allowAction,"params":params});
                }
            }
            this.optionParameterShow = true
            // this.scrollToTop()
        },
        selectTemplate(option) {
            if(option.type == 17) { // option free good
                let line = this.promotion.line[option.optional.idx]
                if(line.type.id == 6 && line.free_item && line.free_item.length>0) {
                    // Specific SKU, maka hanya dapat memilih 1 SKU saja
                    this.$vs.notify({
                        title: "Warning : Can't add this item",
                        text: "Only 1 SKU for Free Item (SKU) allowed",
                        color: "warning",
                        position: "top-right",
                    });
                    return true
                }

                this.addFreeItem(option.optional.idx, {
                  "item_code": option.data.code,
                  "item_id": option.data.item_id,
                  "item_name": option.data.name,
                  "item_unit": option.data.unit,
                  "item_unit_id": option.data.id,
                  "qty": option.data.qty
                })
            } else {
              let type = option.type
              let data = option.data
              const el = this.$refs.template_applicable_to
              if(el) { // if statement here is important!
                  el.setTemplate(type, data);
              }
            }
        },
        removeTemplate(type) {
            switch(type) {
                case 2:
                    this.templateTerritory = []
                    break;
                case 3:
                    this.templateCustomerCategory = []
                    break;
                case 5:
                    this.templatePricingGroup = []
                    break;
                case 9:
                    this.templateCustomerGroup1 = []
                    break;
                case 10:
                    this.templateCustomerGroup2 = []
                    break;
                case 11:
                    this.templateCustomerGroup3 = []
                    break;
                case 12:
                    this.templateCustomerSoldTo = []
                    break;
                case 13:
                    this.templateCustomerShipTo = []
                    break;
                case 23:
                    this.templatePaymentTerm = []
                    break;
                case 24:
                    this.templateDeliveryType = []
                    break;
            }
        },
		    removeTemplateDetail(option) {
            console.log("option", option)
			      switch(option.type) {
				        case 2:
                    this.templateTerritory.splice(option.idx, 1);
                    break;
                case 3:
                    this.templateCustomerCategory.splice(option.idx, 1);
                    break;
                case 5:
                    this.templatePricingGroup.splice(option.idx, 1);
                    break;
                case 9:
                    this.templateCustomerGroup1.splice(option.idx, 1);
                    break;
                case 10:
                    this.templateCustomerGroup2.splice(option.idx, 1);
                    break;
                case 11:
                    this.templateCustomerGroup3.splice(option.idx, 1);
                    break;
                case 12:
                    this.templateCustomerSoldTo.splice(option.idx, 1);
                    break;
                case 13:
                    this.templateCustomerShipTo.splice(option.idx, 1);
                    break;
                case 23:
                    console.log("before", this.templatePaymentTerm.length)
                    this.templatePaymentTerm.splice(option.idx, 1);
                    console.log("after", this.templatePaymentTerm.length)
                    break;
                case 24:
                    this.templateDeliveryType.splice(option.idx, 1);
                    break;
            }
		    },
        addLine(data) {
            let messages = []
            let message = ""
            let isValid = true

            if(data.type.id == null || data.type.id == "" || data.type.id == 0) {
                isValid = false
                message = "Please select type"
                messages.push(message)
            }

            // if(data.valid_from == "" || data.valid_to == "") {
            //     isValid = false
            //     message = "Please select valid from & to"
            //     messages.push(message)
            // }

            // MIX2-3554
            // untuk promo exception
            // if(this.promotion.type==1 && (isNaN(data.disc) || data.disc == null || data.disc == "" || data.disc == 0)) {
            //     isValid = false
            //     message = "Please select main disc"
            //     messages.push(message)
            // }
            if(parseInt(data.disc_per) > 0 && parseInt(data.disc) == 0) {
                isValid = false
                message = "Can't use multiply on promo exception"
                messages.push(message)
            }

            if(data.from > data.to) {
                isValid = false
                message = 'Please check your input, "from" must be equal or lower than "to"'
                messages.push(message)
            }

            if(parseInt(data.disc_per) > 0 && !(data.disc_per_unit && data.disc_per_unit.id != 0)) {
                isValid = false
                message = 'Please check your input, specify for "discount per unit"'
                messages.push(message)
            }

            if(parseInt(this.promotion.sku_type) == 1 && (data.item  == undefined || data.item.id == undefined || data.item.id == 0)) {
                isValid = false
                message = 'Please select specific SKU'
                messages.push(message)
            }

            this.promotion.line.some(element => {
                if (element.combination == data.combination) {
                    let logic1 = (data.from < element.from && data.from < element.to) && (data.to < element.from && data.from < element.to) ? true : false
                    let logic2 = (data.from > element.from && data.from > element.to) && (data.to > element.from && data.from > element.to) ? true : false
                    if((logic1||logic2)==false){
                        isValid = false
                        message = 'Please check your input, "from" must be greater than "to"'
                        messages.push(message)
                        return true;
                    }
                }
            });

            if(!isValid) {
                for (var i=0; i < messages.length; i++) {
                    this.$vs.notify({
                        title: "Warning : Can't add this item",
                        text: messages[i],
                        color: "warning",
                        position: "top-right",
                    });
                }
            } else {
                this.promotion.line.push(data)
                this.sortLine()
            }
        },
        sortLine() {
            this.promotion.line.sort((a, b) => {
                // Extract the numerical values from the "sort" property
                const numA = parseInt(a.sort.split('-').pop());
                const numB = parseInt(b.sort.split('-').pop());

                // Compare the numerical values
                return numA - numB;
            });
        },
        clickDeleteLine(idx) {
            this.promotion.line.splice(idx, 1);
        },
        clickDeleteFreeItem(idx, idx_fi) {
            this.promotion.line[idx].free_item.splice(idx_fi, 1);
        },
        addFreeItem(idx, data) {
            let checkDuplicate = false
            checkDuplicate = this.promotion.line[idx].free_item.some(element => {
                if (element.item_unit_id == data.item_unit_id) {
                    return true;
                }
            });
            if(checkDuplicate) {
                this.$vs.notify({
                    title: "Warning : Can't add this item",
                    text: "Duplicate item detected ",
                    color: "warning",
                    position: "top-right",
                });
            } else {
              this.promotion.line[idx].free_item.push(data);
            }
        },
        setDefaultItemRequisitionType(line){
            if(line.Type == 0 || line.Type == null) {
                line.SelectedType = {
                    id: this.optionType[0].id,
                    name: this.optionType[0].name,
                };
            } else {
                for (let index = 0; index < this.optionType.length; index++) {
                    if(this.optionType[index].id == line.Type) {
                        line.SelectedType = {
                            id: this.optionType[index].id,
                            name: this.optionType[index].name,
                        };
                        break;
                    }
                }
            }
        },
        checkDate(type) {
            let validFrom = null
            let validTo = null

            if(type==1) { // promo date
                validFrom = this.promotion.valid_from
                validTo = this.promotion.valid_to
            } else { // exp date flushout
                validFrom = this.promotion.flush_out_min_exp_date
                validTo = this.promotion.flush_out_max_exp_date
            }

            if(validFrom.getFullYear() > validTo.getFullYear()) {
                console.log(validFrom.getFullYear(), validTo.getFullYear())
                return false;
            }

            if(validFrom.getMonth() > validTo.getMonth()) {
                console.log(validFrom.getMonth(), validTo.getMonth())
                return false;
            }

            if(validFrom.getMonth() == validTo.getMonth() && validFrom.getDate() > validTo.getDate()) {
                console.log(validFrom.getDate(), validTo.getDate())
                return false;
            }

            return true
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.promotion = Object.assign({}, this.resetPromotion);
        },
        CancelForm() {
            location.reload();
            // this.update = false;
            // this.resetPromotion = {
            //     id: 0,
            //     budget: {},
            //     promotion_type: {},
            //     code: null,
            //     name: null,
            //     description: "",
            //     group_code: null,
            //     group_ex_code: null,
            //     allow_partial: 1,
            //     allow_return: 0,
            //     invoice_type: 1,
            //     type: 1,
            //     flush_out: 0,
            //     sku_type: 0,
            //     base: 1,
            //     unit: 1,
            //     calculation: 1,
            //     all_hu: 1,
            //     valid_from: null,
            //     valid_to: null,
            //     flush_out_min_exp_date: null,
            //     flush_out_max_exp_date: null,
            //     budget_id: 0,
            //     promotion_type_id: 0,
            //     line: []
            // }

            // this.promotion = Object.assign({}, this.resetPromotion);
            // this.selectedBudget = {};
            // this.selectedCurrency = {};
            // this.selectedSupplier = {};
            // this.selectedPromotionType = {};

            // this.$emit("selectPromotion", {});
            // this.$emit("formClose");
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/item-available", {
                params: {
                    // territory_code : this.selectTerritoryCode,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.ItemsAvailable;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionBudget() {
            this.$vs.loading();

            this.$http.get("/api/v1/promotion-budget", {
                params: {
                    status: 2,
                    limit: 100,
                    order: 'code',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    if(resp.data.Budgets.length <= 0) {
                        this.optionBudget =[]
                        this.$vs.loading.close();
                        return true
                    }

                    for (let index = 0; index < resp.data.Budgets.length; index++) {
                        let element = resp.data.Budgets[index]
                        if(element.DefaultAttribute.ID == this.promotion.budget_id) {
                            this.selectedBudget = {
                                ID: element.DefaultAttribute.ID,
                                Code: element.Code,
                            };
                            break;
                        }
                        this.optionBudget.push({
                            ID: element.DefaultAttribute.ID,
                            Code: element.Code,
                        })
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Budget option",
                        color: "danger",
                        position: "top-right",
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionPromotionType() {
            this.$vs.loading();

            this.$http.get("/api/v2/master/promotion-type", {
                params: {
                    order: 'code',
                    sort: 'asc',
                    page: 1,
                    limit: 100,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionPromotionType = [];
                    if(resp.data.records.length <= 0) {
                        this.$vs.loading.close();
                        return true
                    }

                    for (let index = 0; index < resp.data.records.length; index++) {
                        let dt = resp.data.records[index]
                        if(dt.ID == this.promotion.promotion_type_id) {
                            this.selectedPromotionType = {
                                ID: dt.ID,
                                Code: dt.code,
                            };
                        }
                        this.optionPromotionType.push({
                            ID: dt.ID,
                            Code: dt.code,
                        })
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Promotion Type option",
                        color: "danger",
                        position: "top-right",
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionCurrency() {
            this.$vs.loading();

            this.$http.get("/api/v1/master/currency", {
                params: {
                    order: "code",
                    sort: "asc",
                },
            }).then((resp) => {
                if (resp.code == 200) {
                    this.optionCurrency = resp.data.records;
                    if (this.promotion.currency_id == 0 || this.promotion.currency_id == null) {
                        let IDRCurrency = this.optionCurrency.filter(function(dt){
                            if(dt.Code == "IDR") {
                                return true
                            }
                        })[0]
                        this.selectedCurrency = {
                            ID: IDRCurrency.ID,
                            Code: IDRCurrency.Code,
                        };
                    } else {
                        for (let index = 0; index < this.optionCurrency.length; index++) {
                            if (this.optionCurrency[index].ID == this.promotion.currency_id) {
                                this.selectedCurrency = {
                                    ID: this.optionCurrency[index].ID,
                                    Code: this.optionCurrency[index].Code,
                                };
                                break;
                            }
                        }
                    }
                } else {
                    this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Currency option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionSupplier() {
            this.$vs.loading();
            this.$http.get("/api/v1/master/suppliers", {
                params: {
                    order: "name",
                    sort: "asc",
                },
            }).then((resp) => {
                if (resp.code == 200) {
                    this.optionSupplier = resp.data.records;
                    if (this.promotion.supplier_id == 0 || this.promotion.supplier_id == null) {
                        this.selectedSupplier = {
                            ID: this.optionSupplier.ID,
                            Code: this.optionSupplier.Code,
                        };
                    } else {
                        for (let index = 0; index < this.optionSupplier.length; index++) {
                            if (this.optionSupplier[index].ID == this.promotion.supplier_id) {
                                this.selectedSupplier = {
                                    ID: this.optionSupplier[index].ID,
                                    Code: this.optionSupplier[index].Code,
                                };
                                break;
                            }
                        }
                    }
                } else {
                    this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Supplier option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                }
                this.$vs.loading.close();
            });
        },
        async handleAttachmant(){
            var formData = new FormData();
            // var bo = true;
            // if (bo) {
            if (this.fileAttachment.length >0) {
                var dateNow = new Date();
                var now =
                dateNow.getFullYear() + "" + (dateNow.getMonth() + 1) + "" + dateNow.getDate();
                now +=
                +"" +
                dateNow.getHours() +
                "" +
                dateNow.getMinutes() +
                "" +
                dateNow.getSeconds();
                formData.append("user_agent", navigator.userAgent);
                for (let i = 0; i < this.fileAttachment.length; i++) {
                    const file = this.fileAttachment[i].file;
                    if (file) {
                        formData.append("file", file);
                        formData.append("promotion_id", this.fileAttachment[i].promotion_id?this.fileAttachment[i].promotion_id:0);
                        formData.append("file_name", now + "_" + file.name);
                    }
                }
                const postData = await this.$http
                .post("/api/v2/promotion/import-attachment", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                if (postData.code==200) {
                    this.$vs.notify({
                        title: "Success",
                        text: postData.message,
                        color: "success",
                        position: "top-right",
                    });
                    this.pa_ids=postData.data.pa_ids
                    // this.CancelForm();
                    // location.reload()
                    // this.setDefaultDate();
                    this.$vs.loading.close();
                }else{
                    this.$vs.notify({
                        title: "Error",
                        text: postData.message,
                        color: "danger",
                        position: "top-right",
                    });
                }
                return postData
            }
            return {code:200,message:""}
        },
        setParam() {
            this.promotion.type = parseInt(this.promotion.type)
            this.promotion.base = parseInt(this.promotion.base)
            this.promotion.unit = parseInt(this.promotion.unit)
            this.promotion.calculation = parseInt(this.promotion.calculation)
            this.promotion.all_hu = parseInt(this.promotion.all_hu)
            this.promotion.allow_partial = parseInt(this.promotion.allow_partial)
            this.promotion.allow_return = parseInt(this.promotion.allow_return)
            this.promotion.invoice_type = parseInt(this.promotion.invoice_type)
            this.promotion.flush_out = parseInt(this.promotion.flush_out)
            this.promotion.sku_type = parseInt(this.promotion.sku_type)
            this.promotion.ownership = parseInt(this.promotion.ownership)

            let param = {
                id: this.promotion.id,
                budget_id: (this.selectedBudget) ? this.selectedBudget.ID : null,
                promotion_type_id: (this.selectedPromotionType) ? this.selectedPromotionType.ID : null,
                currency_id: (this.selectedCurrency) ? this.selectedCurrency.ID : null,
                ownership: this.promotion.ownership,
                supplier_id: (this.promotion.ownership == 2) ? this.selectedSupplier.ID : null,
                code: this.promotion.code,
                name: this.promotion.name,
                group_code: this.promotion.group_code,
                group_ex_code: this.promotion.group_code,
                description: this.promotion.description,
                allow_partial: false,
                allow_return: false,
                flush_out: false,
                sku_type: this.promotion.sku_type,
                type: this.promotion.type,
                base: this.promotion.base,
                unit: this.promotion.unit,
                calculation: this.promotion.calculation,
                all_hu: this.promotion.all_hu,
                valid_from: this.promotion.valid_from,
                valid_to: this.promotion.valid_to,
                flush_out_min_exp_date: this.promotion.flush_out_min_exp_date,
                flush_out_max_exp_date: this.promotion.flush_out_max_exp_date,
                exclude: false,
                line: null,
                territory: [],
                customer_category: [],
                customer_group1: [],
                customer_group2: [],
                customer_group3: [],
                pricing_group: [],
                customer_sold_to: [],
                customer_ship_to: [],
                payment_term: [],
                delivery_type: [],
                pa_ids:this.pa_ids, //promotion_attachment_ids
                pa_del_ids:this.pa_del_ids, //promotion_attachment_ids (delete)
            }

            for (let index = 0; index < this.templateTerritory.length; index++) {
                const element = this.templateTerritory[index]
                param.territory.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerCategory.length; index++) {
                const element = this.templateCustomerCategory[index]
                param.customer_category.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerGroup1.length; index++) {
                const element = this.templateCustomerGroup1[index]
                param.customer_group1.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerGroup2.length; index++) {
                const element = this.templateCustomerGroup2[index]
                param.customer_group2.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerGroup3.length; index++) {
                const element = this.templateCustomerGroup3[index]
                param.customer_group3.push(element.id)
            }
            for (let index = 0; index < this.templatePricingGroup.length; index++) {
                const element = this.templatePricingGroup[index]
                param.pricing_group.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerSoldTo.length; index++) {
                const element = this.templateCustomerSoldTo[index]
                param.customer_sold_to.push(element.id)
            }
            for (let index = 0; index < this.templateCustomerShipTo.length; index++) {
                const element = this.templateCustomerShipTo[index]
                param.customer_ship_to.push(element.id)
            }
            for (let index = 0; index < this.templatePaymentTerm.length; index++) {
                const element = this.templatePaymentTerm[index]
                param.payment_term.push(element.id)
            }
            for (let index = 0; index < this.templateDeliveryType.length; index++) {
                const element = this.templateDeliveryType[index]
                param.delivery_type.push(element.id)
            }

            if(this.promotion.allow_partial==1) {
              param.allow_partial = true
            }

            if(this.promotion.allow_return==1) {
              param.allow_return = true
            }

            if(this.promotion.invoice_type==1) {
              param.on_invoice = true
            }

            if(this.promotion.flush_out==1) {
              param.flush_out = true
            }

            let line = []
            for (let index = 0; index < this.promotion.line.length; index++) {
                const element = this.promotion.line[index];

                let tempLine = {
                    "item_unit_id": (element.item  !== undefined) ? element.item.id : null,
                    "item_id": (element.item  !== undefined) ? element.item.item_id : null,
                    "unit_id": (element.unit_id  !== undefined) ? element.unit_id : null,
                    "item_name": (element.item  !== undefined) ? element.item.name : null,
                    "item_code": (element.item  !== undefined) ? element.item.code : null,
                    "item_unit": (element.item_unit  !== undefined) ? element.item_unit : null,
                    "type": (element.type  !== undefined) ? element.type.id : null,
                    "division_id": (element.division  !== undefined) ? element.division.id : null,
                    "division_code": (element.division  !== undefined) ? element.division.code : null,
                    "item_category_id_1": (element.category_1  !== undefined) ? element.category_1.id : null,
                    "item_category_id_2": (element.category_2  !== undefined) ? element.category_2.id : null,
                    "item_category_code1": (element.category_1  !== undefined) ? element.category_1.code : null,
                    "item_category_code2": (element.category_1  !== undefined) ? element.category_1.code : null,
                    "item_material_id_1": (element.material_1  !== undefined) ? element.material_1.id : null,
                    "item_material_id_2": (element.material_2  !== undefined) ? element.material_2.id : null,
                    "item_material_id_3": (element.material_3  !== undefined) ? element.material_3.id : null,
                    "item_material_code1": (element.material_1  !== undefined) ? element.material_1.code : null,
                    "item_material_code2": (element.material_2  !== undefined) ? element.material_2.code : null,
                    "item_material_code3": (element.material_3  !== undefined) ? element.material_3.code : null,
                    "combo_id": (element.combo  !== undefined) ? element.combo.id : null,
                    "combo_code": (element.combo  !== undefined) ? element.combo.code : null,
                    "mandatory": element.mandatory,
                    "combination": element.combination,
                    "multiply": true,
                    "qty": 1,
                    "step": [],
                }

                let checkLine = line.filter(function(dt){
                    if(dt.combination == element.combination) {
                        return true
                    }
                })[0]

                if(!(checkLine && checkLine.combination != "")) {
                    line.push(tempLine)
                }

                let tempFreeItem = []
                for (let index2 = 0; index2 < element.free_item.length; index2++) {
                    const element2 = element.free_item[index2];
                    tempFreeItem.push({
                        "item_unit_id": element2.item_unit_id,
                        "item_id": element2.item_id,
                        "item_name": element2.item_name,
                        "item_code": element2.item_code,
                        "item_unit": element2.item_unit,
                        "qty":element2.qty,
                    })
                }

                let step = line.filter(function(dt){
                    if(dt.combination == element.combination) {
                        return true
                    }
                })[0].step

                step.push({
                    "from" : element.from,
                    "to" : element.to,
                    "disc" : element.disc,
                    "disc_add" : element.disc_add,
                    "disc_cap" : element.disc_cap,
                    "disc_per" : element.disc_per,
                    "disc_per_unit_id" : element.disc_per_unit.id,
                    "disc_per_unit" : element.disc_per_unit.name,
                    "free_item": tempFreeItem
                })

            }

            param.line = line

            console.log("this.promotion.line : ", this.promotion.line)
            console.log("param : ", param)
            // console.log("json(param) : ", JSON.stringify(param))
            return param
        },

        async SubmitForm() {
          	if(this.promotion.group_code == "" || this.promotion.group_code == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please fill 'Bulletin Code' first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(this.promotion.description == null || this.promotion.description == "" || this.promotion.description.length <= 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please fill 'Description' first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(parseInt(this.promotion.ownership) == 2 && (this.selectedSupplier.ID == "" || this.selectedSupplier.ID == 0 || this.selectedSupplier.ID == undefined)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select 'Supplier' first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

          	if(this.selectedPromotionType == null || this.selectedPromotionType.ID == "" || this.selectedPromotionType.ID == null || this.selectedPromotionType.ID == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select 'Promotion Type' first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

          	// if(this.selectedBudget == null || this.selectedBudget.ID == "" || this.selectedBudget.ID == null || this.selectedBudget.ID == 0) {
            //     console.log("this.selectedBudget", this.selectedBudget)
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Please select 'Promotion Budget' first",
            //         color: "danger",
            //         position: "top-right",
            //     });
            //     return true;
            // }

            if(this.promotion.valid_from == "" || this.promotion.valid_from == 0 || this.promotion.valid_from == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid from first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(this.promotion.valid_to == "" || this.promotion.valid_to == 0 || this.promotion.valid_to == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid to first",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(parseInt(this.promotion.flush_out) == 1 && (this.promotion.flush_out_min_exp_date == "" || this.promotion.flush_out_min_exp_date == 0 || this.promotion.flush_out_min_exp_date == null)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Min Exp Date",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(parseInt(this.promotion.flush_out) == 1 && (this.promotion.flush_out_max_exp_date == "" || this.promotion.flush_out_max_exp_date == 0 || this.promotion.flush_out_max_exp_date == null)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Max Exp Date",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(!this.checkDate(1)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid date range, must be equal or after valid from",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(parseInt(this.promotion.flush_out) == 1 && !this.checkDate(2)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid date range, must be equal or after min expired date",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            if(this.promotion.line.length == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please setup your promotion line",
                    color: "danger",
                    position: "top-right",
                });
                return true;
            }

            // if(this.selectedBudget.Code == "" || this.selectedBudget.Code == 0 || this.selectedBudget.Code == null) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Please select Budget",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check"
            //     });
            //     return true;
            // }

            // let hmm = true
            // if(hmm) {
            //     this.setParam()
            //     return true
            // }
            const paData = await this.handleAttachmant()
            // console.log("paData",paData)
            this.$vs.loading();
            if (paData.code==200) {
                // console.log("start");
                this.$http
                .post("/api/v2/promotion/store", this.setParam())
                .then(resp => {
                    // console.log(resp);
                    if (resp.code == 200) {
                        this.$vs.notify({
                            title: "Success",
                            text: "Promotion has been saved",
                            color: "success",
                            position: "top-right",
                        });
                        this.promotion.code = resp.data.code
                        this.allowSubmit = false
                        this.scrollToTop()
                        // this.CancelForm();
                        // location.reload()
                        // this.setDefaultDate();
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: resp.message,
                            color: "danger",
                            position: "top-right",
                        });
                    }
                    this.$vs.loading.close();
                });
            }
        },
        previewFiles(event) {
            for (let i = 0; i < event.target.files.length; i++) {
                const el = event.target.files[i];
                let size = parseInt(el.size)//byte

                this.fileAttachment.push({
                    path:"",
                    file_name: el.name,
                    size:Math.ceil(size/1024),
                    promotion_id:this.promotion.id,
                    file:el,
                })

            }
            console.log(event.target.files);
        },
        setDefaultDate() {
            var day = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1);

            this.promotion.Date = day
            this.promotion.DueDate = nextDay
        },
        setDefaultPromotionLineType() {
            // all possibilities
            // this.optionType = [
            //     {id: 1,name: "Percentage"},
            //     {id: 2,name: "Amount"},
            //     {id: 3,name: "Fix Amount"},
            //     {id: 4,name: "Fix Item"},
            //     {id: 5,name: "Lowest Price"},
            //     {id: 6,name: "SKU"},
            //     {id: 7,name: "Merchandise"},
            //     {id: 9,name: "Choice"},
            // ],

            // Jika type = "discount"
            if(this.promotion.type==1) {
                this.optionType = [
                    {id: 1,name: "Percentage"},
                    {id: 2,name: "Amount"},
                    // {id: 3,name: "Fix Amount"},
                    // {id: 4,name: "Fix Item"},
                ]
            } else if(this.promotion.type==2) {
                this.optionType = [
                    {id: 5,name: "Lowest Price"},
                    {id: 6,name: "SKU"},
                    // {id: 7,name: "Merchandise"},
                    {id: 9,name: "Choice"},
                ]
            }

            const el = this.$refs.template_applicable_to
            if(el) { // if statement here is important!
                if(this.promotion.line.length > 0) {
                  el.setTemplateType(this.promotion.line[0].type.id)
                }
            }
        },
        setDefaultTemplate(type, template, ids) {
            if(ids.length == 0) {
                return true
            }
            // 2: territory
            // 3: customer_category (dist channel)
            // 5: pricing group (cust group 4)
            // 9: customer group 1
            // 10: customer group 2
            // 11: customer group 3
            // 12: customer sold to (customers)
            // 13: customer ship to (customer address)
            // 14: division
            // 15: combo
            // 16: item (sku + hu)

            let tempData = null
            var parameter = {
                limit: 1000,
                length: 1000,
                page: 1,
                offset: 0,
                ids: ids,
            };
            let url = ""
            switch(type) {
                case 1:
                    url = "/api/v1/master/territory-area"
                    break;
                case 2:
                    url = "/api/v1/master/territory"
                    break;
                case 3:
                    url = "/api/v1/master/customer-category"
                    break;
                case 4:
                    url = "/api/v1/master/customer-group"
                    break;
                case 5:
                    url = "/api/v1/master/pricing-group"
                    break;
                case 6:
                    url = "/api/v1/master/payment-term"
                    break;
                case 7:
                    url = "/api/v1/master/delivery-type"
                    break;
                case 8:
                    url = "/api/v2/promotion"
                    break;
                case 9:
                    url = "/api/v1/master/customer-group"
                    parameter.level = 1
                    break;
                case 10:
                    url = "/api/v1/master/customer-group"
                    parameter.level = 2
                    break;
                case 11:
                    url = "/api/v1/master/customer-group"
                    parameter.level = 3
                    break;
                case 12:
                    url = "/api/v1/master/customer-address"
                    break;
                case 13:
                    url = "/api/v1/master/customer-address"
                    break;
                case 14:
                    url = "/api/v1/master/division"
                    break;
                case 15:
                    url = "/api/v1/master/combosv2"
                    break;
                case 16:
                    url = "/api/v1/master/list-item-unit"
                    break;
            }
            if(url=="") {
                return true
            }

            this.$http.get(url, {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    tempData = resp.data.records;
                    for (let index = 0; index < tempData.length; index++) {
                        const dataParameter = tempData[index];
                        let data = {}
                        switch(type) {
                            // case 2:
                            //     data.id = dataParameter.id
                            //     data.code = dataParameter.code
                            //     data.name = dataParameter.name
                            //     break;
                            case 3:
                                data.id = dataParameter.id
                                data.code = dataParameter.name
                                data.name = dataParameter.name
                                break;
                            case 5:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.name
                                break;
                            case 9:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.name
                                break;
                            case 10:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.name
                                break;
                            case 11:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.name
                                break;
                            case 12:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.contact_name
                                break;
                            case 13:
                                data.id = dataParameter.ID
                                data.code = dataParameter.code
                                data.name = dataParameter.contact_name
                                break;
                            // case 14:
                            //     data.id = dataParameter.id
                            //     data.code = dataParameter.code
                            //     data.name = dataParameter.name
                            //     break;
                            // case 15:
                            //     data.id = dataParameter.id
                            //     data.code = dataParameter.code
                            //     data.name = dataParameter.name
                            //     break;
                            default:
                                data = dataParameter
                                data.id = (dataParameter.ID) ? dataParameter.ID : dataParameter.id
                        }
                        template.push(data)
                    }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                    });
                }
            });
        },
        setLine() {
            if(this.promotion.line.length == 0) {
                return true
            }

            let tempArr = []
            for (let i=0; i < this.promotion.line.length; i++) {
                let data = this.promotion.line[i]
                let tempData = []
                for (let j=0; j < data.step.length; j++) {
                    let step = data.step[j]
                    // console.log("sku ", tempItem.item.code, "from ", tempItem.from, "to ", tempItem.to)
                    let tempDataFreeItem = []
                    for (let k=0; k < step.free_item.length; k++) {
                        let element3 = step.free_item[k]
                        tempDataFreeItem.push({
                            "item_code": element3.item_code,
                            "item_id": element3.item_id,
                            "item_name": element3.item_name,
                            "item_unit": element3.item_unit,
                            "item_unit_id": element3.item_unit_id,
                            "qty": element3.qty
                        })
                    }

                    let combination = ""
                    combination = data.division_code
                    combination += "-"+data.item_material_code1+"-"+data.item_material_code2+"-"+data.item_material_code3
                    combination += "-"+data.item_category_code1+"-"+data.item_category_code2
                    combination += "-"+data.combo_code+"-"+data.item_code+"-"+data.item_unit

                    tempData.push({
                        "id": step.id,
                        "type": this.optionType.filter(function(opt){
                            if(opt.id == data.type) {
                                return true
                            }
                        })[0],
                        "valid_from": data.valid_from,
                        "valid_to": data.valid_to,
                        "division": {id: data.division_id, code: data.division_code, name: data.division_code},
                        "combo": {id: data.combo_id, code: data.combo_code, name: data.combo_code},
                        "item": {id: data.item_unit_id, code: data.item_code, name: data.item_name, full_text: data.item_code+" "+data.item_name+", "+data.item_unit, item_id: data.item_id, unit: data.item_unit, unit_id:data.unit_id},
                        "unit_id": data.unit_id,
                        "item_unit": data.item_unit,
                        "from": step.from,
                        "to": step.to,
                        "disc": step.discount,
                        "disc_add": step.additional_discount,
                        "disc_cap": step.cap,
                        "disc_per": step.discount_per,
                        "disc_per_unit": {id: step.discount_per_unit_id, name: step.discount_per_unit},
                        "free_item": tempDataFreeItem,
                        "category_1": {id: data.item_category_id_1, code: data.item_category_code1, name: ""},
                        "category_2": {id: data.item_category_id_2, code: data.item_category_code2, name: ""},
                        "material_1": {id: data.item_material_id_1, code: data.item_material_code1, name: ""},
                        "material_2": {id: data.item_material_id_2, code: data.item_material_code2, name: ""},
                        "material_3": {id: data.item_material_id_3, code: data.item_material_code3, name: ""},
                        "mandatory": data.mandatory,
                        "combination": combination,
                        "sort": data.division_code+"-"+data.combo_code+"-"+data.item_code+"-"+data.item_unit+"-"+step.from+"-"+step.to,
                    })

                    // console.log("step ", step.from, "-", step.to)
                }

                // console.log("tempData", tempData)
                tempArr = tempArr.concat(tempData)
            }

            // console.log("this.optionType", this.optionType)
            console.log("tempArr", tempArr)
            this.promotion.line = tempArr
            this.sortLine()
        },
        handleDeleteAttachment(i, id){
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You want to delete "+
                    this.fileAttachment[i].file_name+
                    (this.fileAttachment[i].size>0?" ("+this.fileAttachment[i].size+"kb)":""),
                accept:this.acceptDeleteAttachment,
                parameters: [i,id]
                // cancel: () => {
                //     this.settlement.selected = before
                //     return
                // },
            });
        },

        acceptDeleteAttachment(params){
            console.log(params)
            const i = parseInt(params[0])
            const id = parseInt(params[1]?params[1]:0)
            this.fileAttachment = this.fileAttachment.filter((item, i2) =>{
                if (i==i2) {
                    console.log(item)
                    if (item.id>0) {
                        this.pa_del_ids.push(item.id)
                    }
                }
                return i != i2
            })
            console.log("this.pa_del_ids",this.pa_del_ids)
        },

        handleSelectBudget(v){
            console.log(v)
        }
    },
};
</script>
